define('search',['routes'], function (routes) {
  var input = document.querySelector('[data-component="search-autocomplete"]');
  const form = document.getElementById('nhs_megaMenuSearchForm');

  if (!form) {
    return;
  }

  function attachSearchEvents() {
    require(['SearchFormComponent', 'loadCss'], function (SearchFormComponent, loadCss) {
      var search = new SearchFormComponent({
        form: form,
        shouldAlwaysSubmitForm: true,
        submitButton: document.querySelector('[data-search-submit-megamenu]'),
        useAutocompleteForSubmit: true,
        validationError: document.querySelector('#nhs_field_validation_error'),
        searchTextField: form.querySelector("[data-component-rol='target-input']"),
      });
      const cssInput = document.querySelector('#typeAheadCss');
      if (cssInput) {
        loadCss(cssInput.value);
      }
    });

    input.removeEventListener('mouseover', attachSearchEvents);
  }

  input.addEventListener('mouseover', attachSearchEvents);
});

