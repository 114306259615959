(function (global, factory) {
    "use strict";

    if ("function" === typeof define && define.amd) {
        // If supports for AMD is available return the value to the AMD Loader
        // and to the global (window) object in case any JS module is expecting
        // the lazyLoad to exists on the window object.
        define('lazyLoad',[], function () {
            return (global.lazyLoad = factory(global));
        });
    } else if ("object" === typeof exports) {
        // If supports for Common JS is available export the module
        // and pass is value to the global (window) object in case non Common JS code 
        // is expected to work with this lazyLoad.
        module.exports = (global.lazyLoad = factory(global));
    } else {
        // If not support for AMD or Common JS is available register the module in the global scope.
        global.lazyLoad = factory(global);
        global.lazyLoad.init();
    }
}("undefined" !== typeof window ? window : {}, function (root) {
    "use strict";

    var listingContainer = root.document.getElementById("listing-container");
    var lazySelectors = "[data-lazy-load], .nhs_lazyLoadImage, img.async, img.asyncBrand";
    var viewportMarginObserver;
    var spriteIntersectionObserver;

    var removeClass = function () {
      if (!this) {
        return;
      }

      var lazyElements = [].slice.call(this.querySelectorAll(lazySelectors)) || [];
      lazyElements.push(this);
      lazyElements.forEach(function(element) {
        element.dataset.srcset 
        && element.srcset
        && element.getAttribute('data-img-error') !== 'true'
        && element.dataset.srcset !== element.srcset 
        && loadFullImage(element);
        element.classList.remove("nhs_lazyLoadImage", "nhs_lazyLoadSize", "async", "asyncBrand");
        element.removeAttribute("data-lazy-load");
        element.removeAttribute("data-src");
      });
    };

    var attachNoPhotosAvailable = function () {
      this.classList.remove("nhs_lazyLoadImage", "nhs_lazyLoadSize", "async");
      this.removeAttribute("data-lazy-load");
      this.removeAttribute("data-src");
      const noPhotoElement = this.dataset.noPhotoElement;
      const noPhotoInline = document.querySelector(`[data-no-photo="${noPhotoElement}"]`);
      if (noPhotoInline) {
        noPhotoInline.removeAttribute('hidden');
        const pictureElement = this.closest('picture');
        if (pictureElement) {
            pictureElement.remove();
        } else {
            this.remove();
        }
        return;
      }
      if (typeof this.dataset.onErrorSrc === 'string') {
        this.src = this.dataset.onErrorSrc;
        this.dispatchEvent(new CustomEvent("onImageLoadError"));
      } else {
        this.src =
          "https://beta-nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/no_photos_180x120.png";
        if (this.srcset) {
          //This will be needed since there are invalid images and there's the scenario where we also need to update the
          // srcset and not only the src.
          this.srcset =
            "https://beta-nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/no_photos_180x120.png";
        }
      }
      this.setAttribute('data-img-error', 'true');
      removeSources(this);
      removeClass(this);
    };

    function removeSources(img) {
        var picture = img.closest('picture');
        if(picture) {
          picture.querySelectorAll('source').forEach(function(element) {
            picture.removeChild(element);
          });
        }
    }

    /**
     * Function used to lazy load videos
     * @param {*} lazyVideo 
     */
    function loadFullVideo(lazyVideo) {
        for (var source in lazyVideo.children) {
            var videoSource = lazyVideo.children[source];

            if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
            }
        }

          lazyVideo.load();
          removeClass.call(lazyVideo);
    }

    function loadFullImage(lazyImage) {
        if (lazyImage.target) {
            lazyImage = lazyImage.target;
        }

        if (!lazyImage) {
            lazyImage = this;
        }

        var srcsetImage = lazyImage.dataset.srcset;
        if (lazyImage.tagName !== 'SOURCE') {
            lazyImage.onload = removeClass;

            if (typeof lazyImage.dataset.hasCustomErrorHandling === 'undefined') {
              lazyImage.onerror = attachNoPhotosAvailable;
            }
        }

        if (srcsetImage !== "" && srcsetImage) {
            var srcsetValues = srcsetImage.split(" ");

            if (srcsetValues[0] && srcsetValues[0].indexOf("http") >= 0) {
                if (lazyImage.tagName !== 'SOURCE') {
                    lazyImage.src = srcsetValues[0];
                }
                lazyImage.srcset = srcsetImage;
            }
        } else if (lazyImage.dataset.src) {
            lazyImage.src = lazyImage.dataset.src;
        } else {
            removeClass.call(lazyImage);
        }
    }

    function viewportIntersectionObserver() {
        var lazyImages = [].slice.call(root.document.querySelectorAll(lazySelectors));
        var config = {
            root: null,
            rootMargin: "200px 0px",
            threshold: 0.25
    };

		if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window)) {
			for (var i = 0; i < lazyImages.length; i++) {
				var element = lazyImages[i];
				loadFullImage(element);
			}
		} else {
			viewportMarginObserver = new IntersectionObserver(function (entries) {

                for (var i = 0; i < entries.length; i++) {
                    var entry = entries[i];

                    if (entry.intersectionRatio > 0 && entry.isIntersecting) {
                    var lowQualityImage = entry.target.getAttribute("data-lowquality");
                    var lazyImage = entry.target;

                      if (lowQualityImage) {
                        entry.target.removeAttribute("data-lowquality");
                        lazyImage.onload = loadFullImage;
                        lazyImage.onerror = loadFullImage;
                        lazyImage.src = lowQualityImage;
                        viewportMarginObserver.unobserve(lazyImage);
                      } else {
                        if(lazyImage.tagName === 'VIDEO'){
                            loadFullVideo(lazyImage);
                        } else{
                            loadFullImage(lazyImage);
                        }
                        viewportMarginObserver.unobserve(lazyImage);
                      }
                    }
                }
			}, config);

            for (var index = 0; index < lazyImages.length; index++) {
                var lazyImage = lazyImages[index];
                viewportMarginObserver.observe(lazyImage);
            }
		}
    }

    function getSprites() {
        return document.querySelectorAll("[data-lazysprite]");
    }

    function lazyLoadSpriteImages() {
        var spriteImages = getSprites();

        var config = {
            root: null,
            rootMargin: "50px 0px"
        };

		if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window)) {
			for (var i = 0; i < spriteImages.length; i++) {
				var element = spriteImages[i];
				element.classList.add(element.dataset.lazysprite);
			}
		} else {
			spriteIntersectionObserver = new IntersectionObserver(function (entries) {
                for (var index = 0; index < entries.length; index++) {
                    var entry = entries[index];
                    
                    if (entry.intersectionRatio > 0 && entry.isIntersecting) {
						var spriteImage = entry.target;

						spriteImage.classList.add(spriteImage.dataset.lazysprite);

						spriteIntersectionObserver.unobserve(spriteImage);
					}
                }
			}, config);

            for (var index = 0; index < spriteImages.length; index++) {
                var spriteImage = spriteImages[index];
                
                spriteIntersectionObserver.observe(spriteImage);
            }

		}
    }

    // ReSharper disable once InconsistentNaming
    function LoadImages() {
        viewportIntersectionObserver();
        lazyLoadSpriteImages();
    }

    if (listingContainer) {
        var observer = new MutationObserver(function () {
            LoadImages();
        });

        observer.observe(listingContainer, {
            attributes: true,
            childList: true,
            characterData: true
        });
    }

    function loadNoLazyLoadImageError() {
        document
        .querySelectorAll('img[data-error-image-no-lazy]')
        .forEach(function(element) {
            removeSources(element);
        });
    }

    var init = function () {
        LoadImages();
    };

    /**
     * Checks if an HTML Element is on the Viewport.
     *
     * @private 
     * @param {HTMLElement} element Element to check.
     * @param {Number} margin Margin
     * @returns {Boolean} Wheter the Element is on the viewport or not.
     */
    var isOnViewport = function (element, margin) {
        if (!element || !element.getBoundingClientRect)
            return false;

        margin = margin ? margin : 0;
        var rect = element.getBoundingClientRect();

        return !!(rect.width && rect.height && (
            rect.bottom + margin >= 0 && rect.right + margin >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) + margin &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth) + margin));
    };

    var onViewportElements = [];
    var offViewportElements = [];    
    var onElementsVisibilityChangeHandler = function (changes) {        
        changes = Array.from(changes);

        onViewportElements.filter(function(info){
            return !!changes.find(function(c){
                return c.isIntersecting && c.target === info.element;
            });
        }).forEach(function(info){
            info.callback();
        });

        offViewportElements.filter(function(info){
            return !!changes.find(function(c){
                return !c.isIntersecting && c.target === info.element;
            });
        }).forEach(function(info){
            info.callback();
        });               
    };
    var intersectionObserver = window.IntersectionObserver ? new IntersectionObserver(onElementsVisibilityChangeHandler) : void(0);    

    var addOnViewportListener = function (element, callback, margin) {
        onViewportElements.push({element : element, callback : callback, margin: margin});
        intersectionObserver.observe(element);
    };

    var addOffViewportListener = function(element, callback, margin){
        offViewportElements.push({element : element, callback : callback, margin: margin});
        intersectionObserver.observe(element);
    };

    var removeOnViewportListener = function (element, callback) {
        var info = onViewportElements.find(function(info){
            return info.element === element && info.callback === callback;
        });

        if(!info) return;

        intersectionObserver.unobserve(info.element);
        onViewportElements.splice(onViewportElements.indexOf(info), 1);
    };

    var removeOffViewportListener = function(element, callback){
        var info = offViewportElements.find(function(info){
            return info.element === element && info.callback === callback;
        });

        if(!info) return;

        intersectionObserver.unobserve(info);
        offViewportElements.splice(offViewportElements.indexOf(info), 1);      
    };

    loadNoLazyLoadImageError();
    return {
        init: init,
        isOnViewport : isOnViewport,
        addOnViewportListener: addOnViewportListener,
        addOffViewportListener: addOffViewportListener,
        removeOnViewportListener : removeOnViewportListener,
        removeOffViewportListener : removeOffViewportListener,
        loadNoLazyLoadImageError: loadNoLazyLoadImageError,
    };
}));
