(function headerBackArrow() {
  'use strict';

  /**
 * Handles the functionality of the back label for mobile navigation.
 *
 * This script adds event listeners to sub-navigation labels and the back label
 * to toggle the visibility of the back label when navigating through the mobile menu.
 */

  const subnavLabels = document.querySelectorAll('[data-sub-nav-label]');
  const backLabel = document.querySelector('[data-back-menu-label]');

  function handleSubnavLabelClick() {
    // Show the back label when a sub-navigation label is clicked
    backLabel.classList.add('nhs-c-nav__show-back-label');
  }

  subnavLabels.forEach((label) => {
    label.addEventListener('click', handleSubnavLabelClick);
  });

  // Function to handle back label click
  function handleBackLabelClick() {
    // Hide the back label when it is clicked
    backLabel.classList.remove('nhs-c-nav__show-back-label');
  }

  // Check if the back label element exists
  if (backLabel) {
    // Add a click event listener to the back label
    backLabel.addEventListener('click', handleBackLabelClick);
  }
}(window));

define("headerBackArrow", function(){});

